import {BrowserRouter, Route, Routes} from 'react-router-dom';

import Room from './pages/Room';
import Main from './pages/Main';
import NotFound404 from './pages/NotFound404';
import {RtcContextProvider} from "./contexts/RtcContext";

function App() {
    return (
        <RtcContextProvider>
            <BrowserRouter>
                <Routes>
                    <Route exact path='/room/:id/:client_id' element={<Room />} />
                    <Route exact path='/' element={<Main />} />
                    <Route element={<NotFound404 />} />
                </Routes>
            </BrowserRouter>
        </RtcContextProvider>
    );
}

export default App;
