import { useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import Select from 'react-select';
import { LOCAL_VIDEO, useRtc } from "../../contexts/RtcContext";

function layout(clientsNumber = 1) {
  const pairs = Array.from({ length: clientsNumber }).reduce(
    (acc, next, index, arr) => {
      if (index % 2 === 0) {
        acc.push(arr.slice(index, index + 2));
      }

      return acc;
    },
    []
  );

  const rowsNumber = pairs.length;
  const height = `${100 / rowsNumber}%`;

  return pairs
    .map((row, index, arr) => {
      if (index === arr.length - 1 && row.length === 1) {
        return [
          {
            width: "100%",
            height,
          },
        ];
      }

      return row.map(() => ({
        width: "50%",
        height,
      }));
    })
    .flat();
}

export default function Room() {
  const { id: roomID, client_id } = useParams();
  const { connect, clients, provideMediaRef, localMediaStream, msgs, send, audio, video, peerConnections } = useRtc();
  const videoLayout = layout(clients.length);
  const textInput = useRef();
  // const audioOptions = audio.map((value, id) => {
  //   return {
  //     label: value
  //   }
  // })

  useEffect(() => {
    connect(roomID, client_id);
  }, [roomID, connect, client_id]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        height: "100vh",
      }}>
      <Select 
        options={audio}
        onChange={async (e) => {
          const audioinp = {"deviceId": e.id};
        
          const constraints = {
            "audio": audioinp,
          }

          navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            const [audioTrack] = stream.getAudioTracks();
            localMediaStream.current.videoTrack = audioTrack;
            Object.values(peerConnections.current).forEach((pc) => {
              const sender = pc
                .getSenders()
                .find((s) => s.track.kind === audioTrack.kind);
                sender.replaceTrack(audioTrack);
            });
          })
          // .catch((err) => {
          //   console.error(`Error happened: ${err}`);
          // });
          console.log("after change", localMediaStream.current, peerConnections.current);
        }}
        defaultValue={{label: "None", id: null}}/>
      <Select
        options={video}
        onChange={async (e) => {
          const videoinp = {
            "deviceId": e.id,
            width: {
              min: 1280,
              ideal: 1920,
              max: 2560,
            },
            height: {
              min: 720,
              ideal: 1080,
              max: 1440
            }
          };
        
          const constraints = {
            "video": videoinp,
          }

          navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            const [videoTrack] = stream.getVideoTracks();
            localMediaStream.current.videoTrack = videoTrack;
            Object.values(peerConnections.current).forEach((pc) => {
              const sender = pc
                .getSenders()
                .find((s) => s.track.kind === videoTrack.kind);
                sender.replaceTrack(videoTrack);
            });
          })
          // .catch((err) => {
          //   console.error(`Error happened: ${err}`);
          // });
          
          console.log("after change", localMediaStream.current, peerConnections.current);
        }}
        defaultValue={{label: "None", id: null}}/>
      <button
        onClick={() => {
          const videoTrack = localMediaStream.current.getVideoTracks()[0];
          videoTrack.enabled = !videoTrack.enabled;
        }}>camera</button>
      <button
        onClick={() => {
          const audioTrack = localMediaStream.current.getAudioTracks()[0];
          audioTrack.enabled = !audioTrack.enabled;
        }}>microphone</button>
      <div style={{ display: "flex", flexDirection: "column" }}>
              {msgs.map((msg, i) => (
                <p key={i}>{msg}</p>
              ))}
              <input ref={textInput} />
              <button
                onClick={() => {
                  send(textInput.current.value);
                  textInput.current.value = "";
                }}
              >send</button>
      </div>
      {clients.map((clientID, index) => {
        return (
          <div key={clientID} style={videoLayout[index]} id={clientID}>
            <video
              width="100%"
              height="100%"
              ref={(instance) => {
                provideMediaRef(clientID, instance);
              }}
              autoPlay
              playsInline
              muted={clientID === LOCAL_VIDEO}
            />
          </div>
        );
      })}
    </div>
  );
}
