import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { v4 } from 'uuid';
import ACTIONS from '../../socket/actions';

const host = 's://api.cherry4xo.ru/videocalls';

export default function Main() {
  const navigate = useNavigate();
  const [rooms, updateRooms] = useState([]);
  const rootNode = useRef();
  const socket = useRef(null);

  useEffect(() => {
    socket.current = new WebSocket('wss://api.cherry4xo.ru/videocalls/lobby');

    socket.current.onopen = () => {
      console.log('WebSocket connection established');
    };

    socket.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      handleMessage(data);
    };

    socket.current.onclose = () => {
      console.log('WebSocket connection closed');
    };

    socket.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      if (socket.current) {
        socket.current.close();
      }
    };
  }, []);

  const handleMessage = (data) => {
    switch (data.type) {
      case ACTIONS.SHARE_ROOMS:
        handleShareRooms(data.rooms);
        break;
      default:
        break;
    }
  };

  const handleShareRooms = (data) => {
    if (rootNode.current) {
      updateRooms(_rooms => [...new Set([..._rooms, ...data.map(d => d.id)])]);
    }
  };

  return (
    <div ref={rootNode}>
      <h1>Available Rooms</h1>

      <ul>
        {rooms.map(roomID => (
          <li key={roomID}>
            {roomID}
            <button onClick={ async () => {
              const { data } = await axios.post(`http${host}/join_room`, null, { params: {room_id: roomID}});
              navigate(`/room/${roomID}/${data.client_id}`);
            }}>JOIN ROOM</button>
          </li>
        ))}
      </ul>

      <button onClick={ async () => {
        const { data } = await axios.post(`http${host}/create_room`);
        updateRooms(rooms => [...rooms, data.room_id]);
        // navigate(`/room/${v4()}`);
      }}>Create New Room</button>
    </div>
  );
}
