import {
  createContext,
  useContext,
  useRef,
  useCallback,
  useEffect,
  useState,
  startTransition,
} from "react";
import useStateWithCallback from "../hooks/useStateWithCallback";
import ACTIONS from "../socket/actions";
import freeice from "freeice";

export const RtcContext = createContext({
  connect: () => {},
  clients: [],
  provideMediaRef: () => {},
  localMediaStream: {},
});

export const LOCAL_VIDEO = "LOCAL_VIDEO";

export function RtcContextProvider({ children }) {
  const [clients, updateClients] = useStateWithCallback([]);

  const addNewClient = useCallback(
    (newClient, cb) => {
      updateClients((list) => {
        if (!list.includes(newClient)) {
          return [...list, newClient];
        }

        return list;
      }, cb);
    },
    [updateClients]
  );

  const peerConnections = useRef({});
  const localMediaStream = useRef(null);
  const peerMediaElements = useRef({
    [LOCAL_VIDEO]: null,
  });
  const [msgs, setMsgs] = useState([]);
  const [audio, setAudio] = useState([]);
  const [video, setVideo] = useState([]);

  const socket = useRef(null);

  const send = useCallback((data) => {
    if (!socket.current) return;

    socket.current.send(JSON.stringify({ type: ACTIONS.NEW_MSG, payload: data }));
  }, []);

  const connect = useCallback(
    (roomID, client_id) => {
      const handleMessage = (data) => {
        switch (data.type) {
          case ACTIONS.ADD_PEER:
            handleNewPeer(data.payload);
            break;
          case ACTIONS.SESSION_DESCRIPTION:
            setRemoteMedia(data.payload);
            break;
          case ACTIONS.ICE_CANDIDATE:
            handleIceCandidate(data.payload);
            break;
          case ACTIONS.REMOVE_PEER:
            handleRemovePeer(data.payload);
            break;
          case ACTIONS.NEW_MSG:
            setMsgs((s) => [...s, data.payload]);
            break;
          case ACTIONS.CHAT_HISTORY:
            data.payload.forEach((message) => {
              setMsgs((s) => [...s, message]);
            })
            break;
          default:
            break;
        }
      };

      socket.current = new WebSocket(
        `wss:/api.cherry4xo.ru/videocalls/ws/${roomID}/${client_id}`
      );

      socket.current.onopen = () =>
        console.log("WebSocket connection established");
      socket.current.onmessage = (event) => {
        handleMessage(JSON.parse(event.data));
      };
      socket.current.onclose = () => console.log("WebSocket connection closed");
      socket.current.onerror = (error) =>
        console.error("WebSocket error:", error);

      async function startCapture() {
        localMediaStream.current = await navigator.mediaDevices.getUserMedia(
          {
          audio: true,
          video: {
            width: {
              min: 1280,
              ideal: 1920,
              max: 2560,
            },
            height: {
              min: 720,
              ideal: 1080,
              max: 1440
            },
          }
        }
        );

        navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            // TODO devices labels
            if (device.kind === "audioinput") {
              // setAudio((s) => [...s, {value: device.label, id: device.deviceId}]); 
              audio.push({label: device.label, id: device.deviceId});
            }
            if (device.kind === "videoinput") {
              // setVideo((s) => [...s, {value: device.label, id: device.deviceId}]);
              video.push({label: device.label, id: device.deviceId});
            }
          });
          }
        );

        addNewClient(LOCAL_VIDEO, () => {
          const localVideoElement = peerMediaElements.current[LOCAL_VIDEO];

          if (localVideoElement) {
            localVideoElement.volume = 0;
            localVideoElement.srcObject = localMediaStream.current;
          }
        });
      }

      startCapture()
        .then(() =>
          socket.current.send(
            JSON.stringify({
              type: ACTIONS.JOIN,
              payload: { room: roomID, client_id },
            })
          )
        )
        .catch((e) => console.error("Error getting userMedia:", e));

      // return () => {
      //         if (socket.current) {
      //             socket.current.close();
      //         }
      //
      //     if (localMediaStream.current) {
      //         localMediaStream.current.getTracks().forEach(track => track.stop());
      //     }
      //
      //     socket.current.send(JSON.stringify({
      //         type: ACTIONS.LEAVE,
      //     }));
      // };
    },
    [addNewClient]
  );

  const handleNewPeer = async ({ peerID, createOffer }) => {
    if (peerID in peerConnections.current) {
      return console.warn(`Already connected to peer ${peerID}`);
    }

    peerConnections.current[peerID] = new RTCPeerConnection({
      iceServers: freeice(),
    });

    peerConnections.current[peerID].onicecandidate = (event) => {
      if (event.candidate) {
        socket.current.send(
          JSON.stringify({
            type: ACTIONS.RELAY_ICE,
            payload: {
              peerID,
              iceCandidate: event.candidate,
            },
          })
        );
      }
    };

    let tracksNumber = 0;
    peerConnections.current[peerID].ontrack = ({ streams: [remoteStream] }) => {
      tracksNumber++;

      if (tracksNumber === 2) {
        // video & audio tracks received
        tracksNumber = 0;
        addNewClient(peerID, () => {
          if (peerMediaElements.current[peerID]) {
            peerMediaElements.current[peerID].srcObject = remoteStream;
          } else {
            // FIX LONG RENDER IN CASE OF MANY CLIENTS
            let settled = false;
            const interval = setInterval(() => {
              if (peerMediaElements.current[peerID]) {
                peerMediaElements.current[peerID].srcObject = remoteStream;
                settled = true;
              }

              if (settled) {
                clearInterval(interval);
              }
            }, 1000);
          }
        });
      }
    };

    localMediaStream.current.getTracks().forEach((track) => {
      peerConnections.current[peerID].addTrack(track, localMediaStream.current);
    });

    if (createOffer) {
      const offer = await peerConnections.current[peerID].createOffer();

      await peerConnections.current[peerID].setLocalDescription(offer);

      socket.current.send(
        JSON.stringify({
          type: ACTIONS.RELAY_SDP,
          payload: {
            peerID,
            sessionDescription: offer,
          },
        })
      );
    }
  };

  const setRemoteMedia = async ({
    peerID,
    sessionDescription: remoteDescription,
  }) => {
    await peerConnections.current[peerID]?.setRemoteDescription(
      new RTCSessionDescription(remoteDescription)
    );

    if (remoteDescription.type === "offer") {
      const answer = await peerConnections.current[peerID].createAnswer();

      await peerConnections.current[peerID].setLocalDescription(answer);

      socket.current.send(
        JSON.stringify({
          type: ACTIONS.RELAY_SDP,
          payload: {
            peerID,
            sessionDescription: answer,
          },
        })
      );
    }
  };

  const handleIceCandidate = ({ peerID, iceCandidate }) => {
    peerConnections.current[peerID]?.addIceCandidate(
      new RTCIceCandidate(iceCandidate)
    );
  };

  const handleRemovePeer = ({ peerID }) => {
    if (peerConnections.current[peerID]) {
      peerConnections.current[peerID].close();
    }

    delete peerConnections.current[peerID];
    delete peerMediaElements.current[peerID];

    updateClients((list) => list.filter((c) => c !== peerID));
  };

  const provideMediaRef = useCallback((id, node) => {
    peerMediaElements.current[id] = node;
  }, []);

  return (
    <RtcContext.Provider
      value={{ send, connect, clients, provideMediaRef, localMediaStream, msgs, audio, video, peerConnections }}
    >
      {children}
    </RtcContext.Provider>
  );
}

export function useRtc() {
  const context = useContext(RtcContext);
  if (!context) throw new Error("Use app context within provider!");
  return context;
}
